<template>
  <b-row>
    <b-col sm="12" xl="8">
      <c-card-action title="Filter" :badge="$store.getters['filter/isStockOpnamesFiltered'] ? 'Filtered':''" collapsed>
        <c-form ref="filterForm" @submit="filterSubmit">
          <div class="d-flex justify-content-between">
            <form-date :max="stockOpnamesFilter.date_2" class="w-100 mr-1" v-model="stockOpnamesFilter.date_1" label="Periode Awal"></form-date>
            <form-date :min="stockOpnamesFilter.date_1" class="w-100" v-model="stockOpnamesFilter.date_2" label="Periode Akhir"></form-date>
          </div>
          <form-input label="No. Transaksi" v-model="stockOpnamesFilter.code"></form-input>
          <b-button class="mr-1" type="submit" variant="primary">Sortir</b-button>
          <b-button @click="resetFilter" variant="outline-warning">Reset</b-button>
        </c-form>
      </c-card-action>
    </b-col>
    <b-col sm="12">
      <c-card title="Daftar">
        <template slot="action">
          <b-button v-if="cP(57)" :to="{name:'stockopnames-create'}" variant="primary"><feather-icon icon="PlusIcon"></feather-icon> Tambah</b-button>
        </template>
        <pagination-table :sortKey.sync="sortKey" :sortDesc.sync="sortDesc" ref="table" endpoint="v1/stock_opnames/index" :fields="fields" :queryParams="filterParams">
          <template v-slot:date="{item}">
            {{item.date | mindate}}
          </template>
          <template v-slot:total_different="{item}">
            Rp.{{item.total_different | formatNumber}}
          </template>
          <template v-slot:id="{item}">
            <b-button-group size="sm">
              <b-button variant="primary" :to="{name:'stockopnames-show',params:{id:item.id}}"><feather-icon icon="FolderIcon"></feather-icon></b-button>
              <!-- <b-button variant="danger" @click="confirmDelete(item)"><feather-icon icon="TrashIcon"></feather-icon></b-button> -->
            </b-button-group>
          </template>
        </pagination-table>
      </c-card>
    </b-col>
  </b-row>
</template>

<script>
import PaginationTable from '@/utils/components/PaginationTable.vue'
import CCard from '@/utils/components/CCard.vue'
import {BButtonGroup,BButton} from 'bootstrap-vue'
import FormDate from '@/utils/components/FormDate.vue'
import CForm from '@/utils/components/CForm.vue'
import CCardAction from '@/utils/components/CCardAction.vue'
import { mapFields } from 'vuex-map-fields'
import FormInput from '@/utils/components/FormInput.vue'

export default {
  components:{
    CCard,
    PaginationTable,
    BButtonGroup,
    BButton,
    FormDate,
    CCardAction,
    CForm,
    FormInput
  },
  data(){
    const fields = [
      {key:'code',label:'No. Nota',sortable:true},
      {key:'date',label:'Tanggal',sortable:true},
      {key:'note',label:'Catatan',sortable:true},
      {key:'total_different',label:'Total Selisih',sortable:true},
      {key:'id',label:'',tdClass:'d-flex justify-content-end'},
    ]
    return {
      fields,
      sortKey:'date',
      sortDesc:true
    }
  },
  computed:{
    ...mapFields('filter',['stockOpnamesFilter']),
    filterParams(){
      const dt = JSON.parse(JSON.stringify(this.stockOpnamesFilter))
      return dt
    },
  },
  methods:{
    confirmDelete(item){
      // this.confirmation(`submitDelete`,`Anda akan menghapus Pemasok <b>${item.name}</b>`,`Apakah Anda Yakin ?`,{id:item.id,name:item.name})
      this.confirmation(`submitDelete`,{text:`Anda akan menghapus Penerimaan <b>${item.code}</b>`,parameter:{id:item.id,code:item.code}})
    },
    filterSubmit(){
      this.$refs.table.refresh()
    },
    resetFilter(){
      const vm = this
      vm.$store.commit('filter/RESET_STOCK_OPNAMES')
      vm.$nextTick(()=>{
        vm.$refs.table.refresh()
      })
    }
  }
}
</script>

<style>

</style>